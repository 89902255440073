import React from 'react'
import './Registration.css'; // Add this CSS file for additional styles

const Registration = () => {
  return (
    <div className="form-container">
        <iframe 
          src="https://forms.gle/CgvRdxTNV2CLJ7RA6" 
          className="google-form"
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
        >
          Loading…
        </iframe>
    </div>
  )
}

export default Registration;
