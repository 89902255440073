import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <>
    <div style={{background: "rgb(0, 151, 50)"}}>
    <div className="footer">
      <div className="logo">
        <h1>Way To Intern</h1>
      </div>

        {/* Links Section */}
        <div className="links">
          <h4>Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>

        {/* Social Section */}
        <div className="links">
          <h4>Social</h4>
          <ul>
            <li><a href="https://t.me/waytointern" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-telegram"></i> Telegram</a></li>
            <li><a href="https://whatsapp.com/channel/0029Va9nCmx1yT2IQWowmX3U" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i> Whatsapp</a></li>
            <li><a href="https://www.linkedin.com/company/way-to-intern" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i> Linkedin</a></li>
            <li><a href="https://www.youtube.com/@WayToIntern" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-youtube"></i> Youtube</a></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="links">
          <h4>Contact</h4>
          <ul>
            <li><a href="mailto:info@waytointern.in"><i className="fa-regular fa-envelope"></i> info@waytointern.in</a></li>
            <li><a href="https://wa.me/918764530346" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i> Whatsapp</a></li>
          </ul>
        </div>
      </div>
      <hr style={{color:"black", width:" 80%", background:"rgb(203, 250, 218)", border:"none", height:"2px", marginBottom:"0"}} />
      <p style={{margin:"2px 0 0 0", paddingBottom:"5px", textAlign:"center", color:"rgb(203, 250, 218)"}}>Made with <i class="fa-solid fa-heart" style={{fontSize:"15px"}}></i>by Way To Intern</p>
      
    </div>
      
    </>
  );
}

export default Footer;
