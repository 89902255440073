import React, { useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import './Intro.css';
import Team from '../../Assets/team-work.jpeg'; // You may want to remove this import if it's not used.
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Intro = () => {

    // Initialize AOS on component mount
    useEffect(() => {
        AOS.init({
            duration: 1000, // You can customize the duration here
        });
    }, []);

    return (
        <>
            <div className="intro">
                <div className="motive">
                    <div className="comp">
                        <i className="fa-solid fa-laptop-code"></i> {/* Changed class to className */}
                        <p>Skill Development</p>
                    </div>

                    <div className="comp">
                        <i className="fa-solid fa-person-chalkboard"></i> {/* Changed class to className */}
                        <p>Mentoring</p>
                    </div>

                    <div className="comp">
                        <i className="fa-solid fa-handshake"></i> {/* Changed class to className */}
                        <p>Networking</p>
                    </div>

                    <div className="comp">
                        <i className="fa-solid fa-user-tie"></i> {/* Changed class to className */}
                        <p>Leadership</p>
                    </div>
                </div>

                <div className="brief" >
                    <h1>Join Us!</h1>
                    <p>At Way To Intern, we are committed to enhancing your skill set and providing opportunities for growth and learning. Join our community today and unlock your potential!</p>
                    <a 
                        href="https://drive.google.com/file/d/1CcfEP1ffrWPGRCbKQFOOCb9rjdwodAfM/view?usp=sharing" 
                        target="_blank" 
                        rel="noopener noreferrer"> {/* Added rel attribute */}
                        <button>Know More</button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Intro;
