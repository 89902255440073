import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Main from './Pages/Main/Main';
import About from './Pages/About/About';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Moving from './Components/Moving/Moving';
import Popup from './Components/Popup/Popup';
import Registration from './Pages/Contest/Registration';


const App = () => {
  return (
    <div className="App">
      <Router>
        <div className="container">
          <Popup />
          <Navbar />
          <Moving />

          

          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/About' element={<About />} />
            <Route path='/Services' element={<Services />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/registration' element={<Registration />} />

          </Routes>

          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default App;
